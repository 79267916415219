import React from "react"
import { Button, Modal } from "semantic-ui-react"

export function PopUpAlert({header, setOpen, open, content}:{setOpen:any, header:string, open:boolean, content:string}){
    return (<Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>
            {content}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Okay
        </Button>
      </Modal.Actions>
    </Modal>
  )
}