import React, { SetStateAction, Dispatch } from 'react';
import { SemanticCOLORS } from 'semantic-ui-react';


export interface ToastInfoInterface{
  color:SemanticCOLORS;
  message:string;
  open:boolean;
}
interface ToastContextInterface {
    info: ToastInfoInterface;
    setToast?: any;
    setToastError?:any;
}
const ToastContext = React.createContext<ToastContextInterface>({
        info:{ "color":"green", message:"", open:false},
      });

export { ToastContext };
