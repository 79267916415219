import * as React from 'react';
import { Icon, Label, Menu, Table, Tab } from 'semantic-ui-react'
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import DealTable from './Deals/DealTable';
import { UserContext } from '../utils/UserContext';
import { Deal } from '../Models';
import BasicState from '../utils/basicComponentState';


export default function Dashboard(){
  const navigate = useNavigate()
  const [state, setState] = React.useState<BasicState>({
    error: null,
    isLoaded: false,
  })
  //@ts-ignore
  const {user, updateUser} = React.useContext(UserContext)
  console.log("Dashboard", user)

  const [deals, setDeals] = React.useState<Deal[]>([])
  React.useEffect(()=>{
    console.log(user.loggedIn)
    if (user.loggedIn){
      fetch(`/api/users/${user.user.id}/deals`)
      .then(res => res.json())
      .then(
        (result:any) => {
          console.log("dashboard /deals", result)
          if (result.error){
            if(result.message == "Please log in"){
              updateUser({loggedIn:false})
              return navigate('/')
            }
          }
          setState({...state,
            isLoaded: true,
          })
          setDeals(result)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setState({
            isLoaded: true,
            error
          })
        }
      )
    } else {
      console.log("here")
      console.log(user)
      navigate('/')
    }
  }, [user])
        const { error, isLoaded } = state;
        if (error) {
            return <div>Error: {(error as Error).message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
          const panes = [
            { menuItem: <Menu.Item key='in_progress'>
                <Icon name="building"/> 'In Progress'
              </Menu.Item>, render: () => (<Tab.Pane>
                <DealTable addButtonVisible={true} deals={deals.filter(deal => deal.dealStatus == "INPROGRESS" )} setDeals={setDeals} refinanced={false}></DealTable>
                </Tab.Pane>) },
            { menuItem:  (
                <Menu.Item key='finish'>
                  <Icon name="checkmark"/>Completed
                </Menu.Item>
              ), render: () => <Tab.Pane>
                <DealTable addButtonVisible={false} deals={ deals.filter(deal => deal.dealStatus == "COMPLETED" )} setDeals={setDeals} refinanced={false}></DealTable>
              </Tab.Pane> },
              { menuItem:  (
                <Menu.Item key='refinance'>
                  <Icon name="money"/>Refinancing
                </Menu.Item>
              ), render: () => <Tab.Pane>
                <DealTable addButtonVisible={false} deals={ deals.filter(deal => deal.dealStatus == "REFINANCING" )} setDeals={setDeals} refinanced={false}></DealTable>
              </Tab.Pane> },
              { menuItem:  (
                <Menu.Item key='refinance'>
                  <Icon name="dollar sign"/>Refinance completed
                </Menu.Item>
              ), render: () => <Tab.Pane>
                <DealTable addButtonVisible={false} deals={ deals.filter(deal => deal.dealStatus == "REFINANCED" )} setDeals={setDeals} refinanced={true}></DealTable>
              </Tab.Pane> }
          ]
            return (<>
              <header className="App-header">
                <img src="nvelogobanner.png" className="App-logo" alt="logo" />
              </header>
              <br></br>
              <Tab panes={panes} />

              </>
            )
        }
    }
