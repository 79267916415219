import React from 'react'
import { Button, Dropdown, Form, Grid, Header, Image, Label, Message, Segment } from 'semantic-ui-react'
import {  Link, useNavigate } from "react-router-dom";
import { ToastContext } from '../utils/ToastContext';
import { STATES } from '../utils/states';

export default function RegisterForm (){
    const navigate = useNavigate()
    const toastContext = React.useContext(ToastContext)
    let [state, setState] = React.useState({
            firstName: '', 
            lastName:"", 
            password:"", 
            email:"",
            dob:"",
            motto:"",
            role:"",
            title:""
        });
    
    function handleChange(event:any, data: any) {
        console.log(event, data)
        setState({...state, [data.name]: data.value});
    }
    function handleSubmit(event:any) {
        event.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(state)
        };
        fetch("/api/users", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
                console.log(result.error)
                if (result.error){
                    console.log(toastContext)
                    toastContext.setToast({color:"red", open:true, message:result.error})
                } else {
                    toastContext.setToast({color:"green", open:true, message:"Registration successful, please log in"})
                    navigate("/")
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                toastContext.setToast({color:"red", open:true, message:"Missing Data"})
              console.log("recieved registration error", error)
            }
          ).catch((err)=>{
            console.log("caught registration error")
          })
        console.log(event)
    }
// TODO: CONFIRM PASSWORD
        return <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' color='yellow' textAlign='center'>
                    <Image src='/nvelogowhite.png' /> Log-in to your account
                </Header>
                <Form size='large' onSubmit={handleSubmit}>
                    <Segment stacked>
                    <Form.Input fluid icon='user' iconPosition='left' placeholder='First Name' name="firstName" value={state.firstName} onChange={handleChange}/>
                    <Form.Input fluid icon='user' iconPosition='left' placeholder='Last Name' name="lastName"  value={state.lastName} onChange={handleChange}/>
                    <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address' name="email" value={state.email} onChange={handleChange}/>
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        name="password"
                        value={state.password} 
                        onChange={handleChange}
                    />
                    <label>Security Motto?</label>
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Security Motto'
                        type='password'
                        name="motto"
                        value={state.motto} onChange={handleChange}
                    />
                    <label>Role?</label>
                    <Dropdown fluid placeholder='Role' options={[{"key":"buyer", "value":"BUYER", "text":"Buyer"},
	{"key":"title", "value":"TITLE", "text":"Title Manager"},
	{"key":"lender", "value":"LENDER", "text":"Private Lender"}]} selection name="role" onChange={handleChange}  value={state.role}/>
                    <br></br>
                    { state.role == "TITLE" && 
                    <Dropdown fluid placeholder='Company?' options={[{"key":"PC", "value":"PC", "text":"PC Title"},
                    {"key":"Layafayette", "value":"LAFAYETTE", "text":"Lafayette Title"},
                    {"key":"premier", "value":"PREMIER", "text":"Premier Settlements"}]} selection name="title" onChange={handleChange}  value={state.title}/>
                    }
                    <br></br>
                    <Button color='yellow' fluid size='large'>
                        Register
                    </Button>
                    </Segment>
                </Form>
                <Message>
                   Already have an account? <Link to='/'>Login</Link>
                </Message>
            </Grid.Column>
        </Grid>
}
