import Geocode from "react-geocode";

// Title company full name translations
export const TitleCompany:any = {
    "PC":"PC Title",
    "LAFAYETTE":"Lafayette Title",
    "PREMIER":"Premier Settlements"
  }

  export const POPUP_DATA_CONTENTS = {
    "INCOMPLETE":{
      "HEADER":()=>"Incomplete information",
      "CONTENT":()=>"The info tab hasnt been completely filled yet or documents have been uploaded, are you sure you want to notify PC title?"
    },
    "UNDO":{
      "HEADER":()=>"Undo step?",
      "CONTENT":()=>"This will undo the finished step"
    },
    "TITLE_COMPANY_REQUIRED":{
      "HEADER":()=>"Title manager required",
      "CONTENT":()=>"Please select a title company"
    },
    "PERMISSION_ERROR":{
      "HEADER":()=>"Invalid permission",
      "CONTENT":(permission:string[])=>{ return `Only a ${permission.map((permission=> permission == "TITLE" ? "Title Manager" : permission.toLowerCase())).join(" or ")} can progress or undo this step`}
    }
  }
// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyDPblG_QTjIbZtIUsq0En4bicR2TyMK8BU");
// set response language. Defaults to english.
Geocode.setLanguage("en");
// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("us");
// Enable or disable logs. Its optional.
Geocode.enableDebug();
  
export const GEOCODE = Geocode

// export enum DealSteps {
//   "File intake", 0 
//   "Title search starting",1
//   "Title cleared",2
//   "Loan approved",3
//   "Funded",4
//   "All parties closed",5
//   "Deal recorded",6
// }
export const STEP_DISPLAY:any = {
  "0":"File intake in progress",
  "1":"File intake complete",
  "2":"Title search in progress",
  "3":"Title cleared",
  "4":"Loan approved",
  "5":"Funded",
  "6":"All parties closed",
  "7":"Deal recorded"
}

// Who can edit which step
export const STEP_PERMISSIONS = [
    ["BUYER"], 
    ["TITLE"],
    ["TITLE"],
    ["BUYER"],
    ["BUYER"],
    ["TITLE"],
    ["TITLE"]
  ]
  