import React from "react";
import { Message as MessageModel } from "../../../Models";
import { ToastContext } from '../../../utils/ToastContext';
import { Button, Comment, Form, TextArea } from "semantic-ui-react";
import { formatDate } from "./Utils";
import { UserContext } from "../../../utils/UserContext";

export const MessagePane = ({dealId, messages, updateDeal, setDealState, unreadMessages}:{unreadMessages:MessageModel[], dealId:string | number | undefined, messages:MessageModel[], updateDeal:(messages:MessageModel[])=>void, setDealState?:any}) =>{
    const [currentMessage, setCurrentMessage] = React.useState("")
    let {setToast} = React.useContext(ToastContext)

    React.useEffect(()=>{
      // Update messages as read
      if (unreadMessages.length == 0){
        return
      }
      let unreadMessageIds = unreadMessages.map((message)=>{return message.id})
      const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({messageIds:unreadMessageIds})
      };
      let url = `/api/messages/updateunread`
      fetch(url,requestOptions)
      .then(res => res.json())
      .then(res => {
        console.log(res)
        setDealState(res.deal)
        // setDealState(res)
              },
              // Note: it's important to handle errors here
              // instead of a catch() block so that we don't swallow
              // exceptions from actual bugs in components.
              (error) => {
                  console.log("here", error)
              }
          ).catch(err=>console.log(err))
    },[])
    function handleSubmit(){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({text:currentMessage})
        };
        let url = `/api/deals/${dealId}/messages`
        if (isNaN(Number(dealId))){
          url = `/api/deals/${dealId}/loanMessages`
        }
        console.log(Number(dealId), url)
        fetch(url,requestOptions)
         .then(res => res.json())
         .then(res => {
                    console.log(res)
                    let message = res.data.message
                    let user = res.data.user
                    message.user = user
                    messages.push(message)
                    updateDeal(messages)
                    setCurrentMessage("")
                    setToast({message:"Message successfully posted.", color:"green", open:true})
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("here", error)
                }
            ).catch(err=>console.log(err))
    }
    function handleChange(_:any, data: any){
        setCurrentMessage(data.value)
    }
    return (<>
  <Comment.Group style={{"maxHeight":"300px", "overflowY":"scroll", "maxWidth":"100%"}}>
  {messages.sort((x,y)=>{
    return x.createdAt > y.createdAt ? -1 : 1
  }).map((message, index)=>{
            return (<Comment key={index}>
                <Comment.Avatar src='/nvelogowhite.png' />
                <Comment.Content>
                  <Comment.Author as='a'>{message.user.firstName} {message.user.role == "LENDER" && "(lender)"} </Comment.Author>
                  <Comment.Metadata>
                    <div >{formatDate(new Date(message.createdAt))}</div>
                  </Comment.Metadata>
                  <Comment.Text  style={{"whiteSpace":"pre-line"}}>{message.text}</Comment.Text>
                  <Comment.Actions>
                    <Comment.Action>Reply</Comment.Action>
                  </Comment.Actions>
                </Comment.Content>
              </Comment>
        )
        })}
  </Comment.Group>
  <Form>
    <Form.Group>
    <TextArea placeholder='Tell us more' name="text" onChange={handleChange} value={currentMessage}/>
    </Form.Group>
    <Button onClick={handleSubmit} content='Add Message' labelPosition='left' icon='edit' primary />
  </Form>
    </>     
    )
}