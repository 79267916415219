import { Icon, List, Popup } from "semantic-ui-react"
import { Deal } from "../../../Models"
import { User } from "../../../Models/User"

export const UserList = ({users, removeUser, sendEmail}:{users:User[], removeUser:Function, sendEmail:Function}) =>{
    return <List divided selected size="big" verticalAlign='bottom'>
    {users.map((user, index)=>{
        return (<List.Item key={index}>
            <Icon name="user" size="tiny" style={{"vertical-align":"middle"}}/>
            <List.Content style={{"display":"inline-block;"}}>{
            `${user.firstName}-${user.email}`.length > 20 ? 
            (`${user.firstName}-${user.email}`.substring(0,20-3) + '...') : 
            `${user.firstName}-${user.email}` }</List.Content>
            
            {user.role == "LENDER" 
                && <Popup 
                    content="Click to send loan approval email" 
                    trigger={<Icon name="paper plane" size="small" style={{"vertical-align":"middle"}} onClick={sendEmail.bind(this, user, "LOAN")}/> } /> }
            {user.role == "BUYER" 
                && <>
                <Popup 
                    content="Click to notify loan term is ready" 
                    trigger={<Icon name="money" size="small" style={{"vertical-align":"middle"}} onClick={sendEmail.bind(this, user, "loan_ready")}/>}/>
                <Popup 
                    content="Click to notify HUD document is ready" 
                    trigger={<Icon name="paper plane" size="small" style={{"vertical-align":"middle"}} onClick={sendEmail.bind(this, user, "hud_ready")}/>}/>
                </>}
            
            <Icon name="delete" size="small" style={{"vertical-align":"middle"}} onClick={removeUser.bind(this, user.id)}/>
        </List.Item>
    )
    })}
    </List>
}