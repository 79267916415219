import React, { Component } from 'react'
import { Dropdown, Input, Menu } from 'semantic-ui-react'
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../utils/UserContext';
import { ToastContext } from '../utils/ToastContext';


export default function MenuNavigation() {
  const LOGGED_PATHS = ['/', '/register', '/forgotpassword']
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = React.useContext(UserContext)
  const {setToast} = React.useContext(ToastContext)
  

  const [state, setState] = React.useState({ activeItem: '' })
  const [userEmail, setUserEmail] = React.useState(userContext?.user?.user?.email)
  const [userPassword, setUserPassword] = React.useState("")

  function updateEmail(){
    console.log(userEmail)
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({email:userEmail, password:userPassword})
    };
    console.log(requestOptions)
    fetch(`/api/users/${userContext?.user?.user?.id}`, requestOptions)
      .then(res => res.json())
      .then(
        (result) => {
          console.log("email change result", result)
          let message = result.message
          if (result.error){
            setToast({message:result.error.toString(), open:true, color:"red"})
          } else {
            userContext?.updateUser({loggedIn:true, user:{...userContext?.user.user, email:userEmail}})
            setToast({message, open:true, color:"green"})
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
        setToast({message:error.toString(), open:true, color:"red"})
        console.log("login error")
        console.log(error)
        }
      )
  }
  React.useEffect(()=>{
    
    console.log(location.pathname)
    console.log(state)
    if (location.pathname == "/dashboard"){
      setState({ activeItem: "All Deals" })    
    } else {
      setState({ activeItem: "" })    
    }
  },[location.pathname])

  React.useEffect(()=>{
    if (!userContext?.user.loggedIn){
      if (!LOGGED_PATHS.find((path)=>{ return location.pathname == path})){
        navigate('/')
      }
    }
  },[userContext?.user])
  function handleItemClick (event:any, { name }:any){
    console.log(event)
    if(name == "logout"){
      console.log("logging out")
      fetch('/api/logout').then((res)=>{
        userContext?.updateUser({loggedIn:false})
        console.log(res)
        setState({ activeItem: '' })
        navigate('/')
      })
    } else {
      setState({ activeItem: name })
      navigate('/dashboard')
    }
  
  } 
    const { activeItem } = state

    return (
      <Menu dividing>
        <Menu.Item onClick={()=>navigate('/')} style={{ "cursor": "pointer"}} ><img style={{ "cursor": "pointer"}} onClick={()=>navigate('/')} sizes='20x20' className="ui avatar circular image" src='/nvelogowhite.png'/>
        </Menu.Item>
        <Menu.Item content={`Role : ${userContext?.user?.user?.role}${userContext?.user?.user?.role == "TITLE" ? ` - ${userContext?.user?.user?.title}` : ""}`}/>
                {userContext?.user.loggedIn &&
        <Menu.Menu position='right'>
          <Menu.Item
            name='All Deals'
            active={activeItem === 'All Deals'}
            color={activeItem === 'All Deals' ? "yellow" : undefined}
            onClick={handleItemClick}
                  
          />
    
          <Dropdown text='Edit Account' pointing className='link item'>
          <Dropdown.Menu>
          <Dropdown.Header icon='user' content={`Role:${userContext?.user?.user?.role}${userContext?.user?.user?.role == "TITLE" ? ` - ${userContext?.user?.user?.title}` : ""}`} />
          <Dropdown.Header icon='mail' content='Email' />
          <Dropdown.Divider />
          <Input onChange={(event, data)=>{setUserEmail(data.value)}} name='email' value={userEmail} onClick={(event:any)=> event?.stopPropagation()} />
          <Input placeholder="password" type="password" onChange={(event, data)=>{setUserPassword(data.value)}} name='password' value={userPassword} onClick={(event:any)=> event?.stopPropagation()} />
          <Dropdown.Item
            icon="check"
            // label={{ color: 'green', empty: true, circular: true }}
            text='Update account'
            onClick={updateEmail}
          />
          </Dropdown.Menu>
        </Dropdown>
          <Menu.Item
            name='logout'
            active={activeItem === 'logout'}
            onClick={handleItemClick}
          />
        </Menu.Menu>
                }

      </Menu>
    )
}