
import * as React from 'react';
import { Icon, Accordion, Tab, Menu, Label, Header, Segment, Transition, Container, List, Button, Progress, Form, TextArea, Comment, Rail, Sticky, Ref, Dropdown, Popup, Confirm, Modal, Input } from 'semantic-ui-react'
import { useParams } from 'react-router-dom';
import axios from 'axios'
import { DealSteps, Steps } from './DealDetails/Utils';

import { displayHouseAddress } from './DealRow';
import { Deal, FileDocument, Task, Message as MessageModel } from '../../Models';
import {useDropzone} from 'react-dropzone';
import { MessagePane } from './DealDetails/MessagePane';
import { InfoPane } from './DealDetails/InfoPane';
import { User } from '../../Models/User';
import Moment from 'moment';
import { UserList } from './DealDetails/UserList';
import { ToastContext } from '../../utils/ToastContext';
import { send } from 'process';
import TextareaAutosize from 'react-textarea-autosize';
import { Tasks } from './DealDetails/Tasks';
import {StyledMapWithAnInfoBox } from '../Map';
import { PopUpAlert } from '../../utils/reusableComponents';
import { UserContext } from '../../utils/UserContext';
import { GeneralPane } from './DealDetails/General';
import { GEOCODE, POPUP_DATA_CONTENTS, STEP_PERMISSIONS, TitleCompany } from '../../utils/constants';


function LoanTermModal({deal, dealId, approveLoan}:{deal:Deal, dealId:string | undefined, approveLoan:Function}) {
  let {setToast} = React.useContext(ToastContext)
  // setOpen(defaultOpen)
  const [open, setOpen] = React.useState(false)
  const [loanTerm, setLoanTerm] = React.useState(deal.loanTerms)
  function updateLoanTerms(){
    console.log(loanTerm)
    if (loanTerm == ""){
      setToast({ "color":"red", message:"Loan Term cannot be blank", open:true })
      return
    }
    let url = `/api/deals/${dealId}/loanterms`
    fetch(url, {
      method:"PUT", 
      body:JSON.stringify({"text":loanTerm}),
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    })
      .then(res => res.json())
      .then(
        (result:{deal:Deal, message:string}) => {
          console.log(result)
          let resDeal = result.deal
          let message = result.message
          setToast({ "color":"green", message, open:true })
          approveLoan(deal.currentStep)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setToast({ "color":"red", message:error, open:true })

        }
      )
  }
  
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      trigger={<div style={{"float":"right"}}>
      <Button 
        disabled={deal.finishedSteps.indexOf(DealSteps['Loan approved']) != -1 } 
        onClick={()=>{
          console.log("approved")
        }}
       color="green">Approve Loan</Button> </div>}>
      <Modal.Header>Loan Terms</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Label>Please enter your loan terms</Label>
          <Form size='large' id='newDealForm'>
              <TextArea fluid icon="info"
               placeholder="Loan terms" 
               name="loanTerm" type="text" 
               value={loanTerm} onChange={(event, data:any)=>{
                setLoanTerm(data.value)
              }} />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='red' onClick={() =>setOpen(false)}>
          Cancel
        </Button>
        <Button
          content="Update Loan Terms"
          labelPosition='right'
          icon='checkmark'
          onClick={() => {
              updateLoanTerms()
              setOpen(false)
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  )
}

export default function DealDetail() {
  function updateLoanTerms(){
    console.log(loanTerm)
    if (loanTerm == ""){
      setToast({ "color":"red", message:"Loan Term cannot be blank", open:true })
      return
    }
    let url = `/api/deals/${dealId}/loanterms`
    fetch(url, {
      method:"PUT", 
      body:JSON.stringify({"text":loanTerm}),
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
    })
      .then(res => res.json())
      .then(
        (result:{deal:Deal, message:string}) => {
          console.log(result)
          let resDeal = result.deal
          let message = result.message
          setToast({ "color":"green", message, open:true })
          approveLoan(deal.currentStep)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setToast({ "color":"red", message:error.toString(), open:true })

        }
      )
  }
  
  let {setToast} = React.useContext(ToastContext)
    let { dealId, action } = useParams();
    const [activePane, setActivePane] = React.useState(0)
    
    React.useEffect(()=>{
      if (action == "update"){
        setActivePane(3)
      }
    }, [action])

    const [deal, setDeal] = React.useState<Deal>(new Deal())
    const [users, setUser] = React.useState<User[]>([])
    const userContext = React.useContext(UserContext)
    const [state, setState] = React.useState<{error: Error | null, isLoaded:boolean}>({
      error: null,
      isLoaded: false,
    })
    const [errorOpen, setErrorOpen] = React.useState(false)
    const [errorPopupData, setErrorPopupData] = React.useState<any>({HEADER:POPUP_DATA_CONTENTS.PERMISSION_ERROR.HEADER, CONTENT:POPUP_DATA_CONTENTS.PERMISSION_ERROR.CONTENT.bind(null, [])})
    
    const [updateUrl, setUpdateUrl] = React.useState("")
    const [loanView, setLoanView] = React.useState(false)
    const [confirmOpen, setConfirmOpen] = React.useState(false)
    const [confirmContent, setConfirmContent] = React.useState(POPUP_DATA_CONTENTS.INCOMPLETE)
    const [loanTermModalOpen, setLoanTermModalOpen] = React.useState(false)
    const [loanTerm, setLoanTerm] = React.useState(deal.loanTerms)
    const [unreadMessages, setUnreadMessages] = React.useState<MessageModel[]>([])
    const [loadedUser, setLoadedUser] = React.useState(false)
    const [loadedDeal, setLoadedDeal] = React.useState(false)

    const [center, setCenter] = React.useState({
      "lat": "",
      "lng": ""
    })
    const [loadingMap, setLoadingMap] = React.useState(true)
    React.useEffect(()=>{
      GEOCODE.fromAddress(`${deal.street1}, ${deal.city}, ${deal.state}, ${deal.zip}`).then(
        (response) => {
          const address = response.results[0].formatted_address;
          setCenter(response.results[0].geometry.location)
          setLoadingMap(false)
          console.log(address);
        },
        (error) => {
          console.log("find address error")
          console.error(error);
        }
      );
      let unreadMessages = deal.messages.filter((message)=>{return message.seenBy.indexOf(userContext?.user.user.id) == -1})
        setUnreadMessages(unreadMessages)
    }, [deal])
    React.useEffect(()=>{
      if (isNaN(Number(dealId))){
        setLoanView(true)
      }
        fetch(`/api/deals/${dealId}`)
          .then(res => res.json())
          .then(
            (result:Deal) => {
                console.log(result)
              setDeal(result)
              setLoadedDeal(true)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setState({
                isLoaded: true,
                error
              })
            }
          )
          fetch('/api/users')
          .then(res => res.json())
          .then(
            (result:User[]) => {
                console.log(result)
              setUser(result)
              setLoadedUser(true)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setState({
                isLoaded: false,
                error
              })
            }
          ).catch((err)=>{
            console.log(err)
          })
    }, [])
    function infoIsValid(){
      let loanInfoIsValid = loanInfoValid()
      console.log(loanInfoIsValid)
      if (!deal.info?.seller?.name
        || !deal.info?.seller?.email
        || !deal.info?.seller?.number
        || !deal.info?.buyer?.name
        || !deal.info?.buyer?.email
        || !deal.info?.buyer?.number
        || !loanInfoValid())
        {
          return false 
        }
        return true
    }
    function loanInfoValid(){
      if (!deal.needLender){
        return true
      } else if (!deal.info.loan.arv
        || !deal.info?.loan?.rehab 
        || !deal.info?.loan?.downpayment 
        || !deal.info?.loan?.purchasePrice){
          return false
      }
      return true
    }
    const updateStep = (index:number) => {
      // console.log()
      let role = userContext?.user.user.role
      if (STEP_PERMISSIONS[index][0] != "ALL" &&  STEP_PERMISSIONS[index].indexOf(role) == -1){
        setErrorPopupData({HEADER: POPUP_DATA_CONTENTS.PERMISSION_ERROR.HEADER, CONTENT:POPUP_DATA_CONTENTS.PERMISSION_ERROR.CONTENT.bind(null, STEP_PERMISSIONS[index])})
        setErrorOpen(true)
        return 
      }
      console.log(index)
      let url = `/api/deals/${dealId}/step`
      url += `/${index}`
      setUpdateUrl(url)
      if (deal.finishedSteps.indexOf(index) != -1){
        setConfirmContent(POPUP_DATA_CONTENTS.UNDO)
        setConfirmOpen(true)
      } else {
        if (index == 0){
          if (deal.title == "" || !deal.title){
            setErrorPopupData(POPUP_DATA_CONTENTS.TITLE_COMPANY_REQUIRED)
            setErrorOpen(true)
          } else {
            if (infoIsValid() && deal.files.length > 0){
              continueUpdateStep(url)
            } else {
              console.log("here2")
              setConfirmContent(POPUP_DATA_CONTENTS.INCOMPLETE)
              setConfirmOpen(true)
            }
          }
          
        } else if (index == 3){
          setLoanTermModalOpen(true)
        }else{
          continueUpdateStep(url)
        }
      }
    }
    function continueUpdateStep(url:string){
      console.log(url)
      
      fetch(url, {method:"PUT"})
      .then(res => res.json())
      .then(
        (result:{deal:Deal, message:string}) => {
          console.log(result)
          let resDeal = result.deal
          let message = result.message
          setToast({ "color":"green", message, open:true})
          setDeal({...deal, currentStep:resDeal.currentStep, finishedSteps:resDeal.finishedSteps})
          setConfirmOpen(false)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setState({
            isLoaded: true,
            error
          })
        }
      )
    }
    const { error, isLoaded } = state;
    const panes = [
        { menuItem: 'Task', onclick:(x: any)=>{console.log(x)}, render: () => (<Tab.Pane>
          
            <Tasks tasks={deal.tasks} deal={deal} updateDeal={setDeal}></Tasks>
            <Segment>
              
                <Header as='h3' dividing>
                    <Icon name='file' />
                    <Header.Content>Documents</Header.Content>
                </Header>
                
                <Files files={deal.files} updateDeal={
                    (files)=>{
                        setDeal({...deal,files:files})
                    }
                } />
                <Segment>
                <OuterDropzone 
                    dealId={dealId} 
                    updateCb={
                        (newFile)=>{ 
                            setDeal({...deal,files:deal.files.concat([newFile])})
                            console.log("here", deal)
                        }
                    }
                />
                </Segment>
            </Segment>
            </Tab.Pane>) },
        { menuItem:  (
            <Menu.Item key='messages'>
              <Icon name="mail"/>Messages<Label>{unreadMessages.length}</Label>
            </Menu.Item>
          ), render: () => <Tab.Pane>
            <MessagePane setDealState={setDeal} unreadMessages={unreadMessages} dealId={dealId} messages={deal.messages} updateDeal={ 
                    (messages)=>{
                        setDeal({...deal,messages:messages})
                    }}/>
          </Tab.Pane> },
          { menuItem:  (
            <Menu.Item key='info'>
              <Icon name="info"/>Deal Info
            </Menu.Item>
          ), render: () => <Tab.Pane>
            <InfoPane deal={deal} updateDeal={ 
                    (deal)=>{
                        setDeal({...deal})
                    }}/>
          </Tab.Pane>
           },
           { menuItem:  (
            <Menu.Item key='general'>
              <Icon name="home"/>File Info
            </Menu.Item>
          ), render: () => <Tab.Pane className='clearing'>
            <GeneralPane deal={deal} updateDeal={ 
                    (deal)=>{
                        setDeal({...deal})
                    }}/>
          </Tab.Pane>
           }
      ]
      let loanTab = { menuItem:  (
        <Menu.Item key='Loan Terms'>
          <Icon name="money"/>Loan Terms
        </Menu.Item>
      ), render: () => <Tab.Pane>
        <Form >
        <Form.Group>
          <TextareaAutosize value={deal.loanTerms}/>
        </Form.Group>
        </Form>
      </Tab.Pane> }
      if (deal.loanTerms && deal.loanTerms != ""){
        panes.push()
      }
    function addUserToDeal(userId:number){
      fetch(`/api/deals/${dealId}/users/${userId}`, {method:"POST"})
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)
            if (result.errors || result.error){
              return
            }
          setDeal(result.data.deal)
          setState({
            isLoaded: true,
            error:null
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setState({
            isLoaded: true,
            error
          })
        }
      )
    }
    function removeUser(index: any){
      console.log(index)
      fetch(`/api/deals/${dealId}/users/${index}`, {method:"DELETE"})
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)
            if (result.errors || result.error){
              return
            }
          let deal = result.data.deal
          setDeal({...deal})
          setState({
            isLoaded: true,
            error:null
          })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setState({
            isLoaded: false,
            error
          })
        }
      )
    }
    function approveLoan(index: any){
      console.log(index)
      fetch(`/api/deals/${dealId}/approveloan`, {method:"PUT"})
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)
            if (result.errors || result.error){
              return
            }
            let message = result.message
            setDeal({...deal, loanTerms:result.deal.loanTerms, finishedSteps:result.deal.finishedSteps})
          setState({
            isLoaded: true,
            error:null
          })
          setToast({ "color":"green", message, open:true })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setState({
            isLoaded: true,
            error
          })
          setToast({ "color":"red", message:error.toString(), open:true })

        }
      )
    }
    function sendEmail(user:User, type="LOAN"){
      let id = deal.id
      console.log(user, type)
      fetch(`/api/users/${user.id}/sendemail`, {method:"POST", 
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        body: JSON.stringify({type:type, dealId:id})})
      .then(res => res.json())
      .then(
        (result) => {
            console.log(result)
            if (result.errors || result.error){
              setToast({ "color":"red", message:`Something went wrong ${error}`, open:true })
              return
            }
            let message = result.message
          setToast({ "color":"green", message, open:true })
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setState({
            isLoaded: true,
            error
          })
          setToast({ "color":"red", message:`Something went wrong ${error}`, open:true })
        }
      )
    }
    function updateDeal(update:Partial<Deal>){
      console.log("propped")
      const requestOptions = {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json; charset=utf-8' },
          body: JSON.stringify(update)
      };
      fetch(`/api/deals/${deal.id}`,requestOptions)
      .then(res => res.json())
      .then(res => {
         console.log(res)
         if(res.error){
             setToast({message:"Info failed to update", color:"red", open:true})
         }else {
             setToast({message:"Information updated", color:"green", open:true})
             setDeal({...deal, ...update})
         }
                
             },
             // Note: it's important to handle errors here
             // instead of a catch() block so that we don't swallow
             // exceptions from actual bugs in components.
             (error) => {
                 console.log("here", error)
             }
         ).catch(err=>console.log(err))
     return false
    }
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!loadedUser || !loadedDeal) {
        return <div>Loading...</div>;
    }  else {
        return (<Segment>
                <PopUpAlert setOpen={setErrorOpen} open={errorOpen} header={errorPopupData.HEADER()} content={errorPopupData.CONTENT()}/>
          <Confirm
            header={confirmContent.HEADER()}
            content={confirmContent.CONTENT()}
            open={confirmOpen}
            onCancel={()=>setConfirmOpen(false)}
            onConfirm={()=>continueUpdateStep(updateUrl)}
          />
        <Modal
          onClose={() => setLoanTermModalOpen(false)}
          onOpen={() => setLoanTermModalOpen(true)}
          open={loanTermModalOpen}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Loan Terms</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Label>Please enter your loan terms</Label>
              <Form size='large' id='newDealForm'>
                  <TextArea fluid icon="info" placeholder="Loan terms" name="loanTerm" type="text" value={loanTerm} onChange={(event, data:any)=>{
                    setLoanTerm(data.value)
                  }} />
              </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() =>setLoanTermModalOpen(false)}>
              Cancel
            </Button>
            <Button
              content="Update Loan Terms"
              labelPosition='right'
              icon='checkmark'
              onClick={() => {
                  updateLoanTerms()
                  setLoanTermModalOpen(false)
              }}
              positive
            />
          </Modal.Actions>
        </Modal>            
        <Rail position='right' style={{"left":"97%"}}>
              <Header as="h4" dividing>Estimated Closing Date</Header>
              <Header as="h3">{Moment(deal.closingDate).format('MMMM D YYYY')}</Header>
              <br></br>
              <Header as="h5" dividing>
                <Popup content='Add a title company' trigger={ <Icon name="info circle" size="tiny"/> } />
                <Header.Content>
                  Title Manager
                </Header.Content>
              </Header>
              <Header as="h4" >{TitleCompany[(deal.title as string)]}</Header>
              {/* <Dropdown placeholder='Add a title' options={[
                    {"key":"PC", "value":"PC", "text":"PC Title"},
                    {"key":"Layafayette", "value":"LAFAYETTE", "text":"Lafayette Title"},
                    {"key":"premier", "value":"PREMIER", "text":"Premier Settlements"}
                  ]
                } selection name="user" onChange={
                  (event, data)=>{updateDeal({title:data.value as string})}}/> */}
                
                <Header as="h5" dividing>
                <Popup content='Add a private lender to be notified of emails' trigger={ <Icon name="info circle" size="tiny"/> } />
                <Header.Content>
                  Lender
                </Header.Content>
              </Header>  
              <UserList users={deal.hasUsers.filter(user=>user.role == "LENDER")} removeUser={removeUser} sendEmail={sendEmail}/>
              {users.filter(
                  (user)=>{
                    return deal.hasUsers.map((u)=>u.email).indexOf(user.email) == -1 && user.role == "LENDER"
                  }).map((user)=> {
                    return <Button color="green" onClick={()=>{addUserToDeal(user.id)}}><Icon name="plus"/>{user.firstName}</Button>
                  })
                }
            </Rail>
            <header className="App-header small">
              {/* @ts-ignore */}
                  <h1>{displayHouseAddress(deal)}</h1>
            </header>

            <Steps updateStep={updateStep} steps={Object.values(DealSteps).splice(0, Object.values(DealSteps).length/2) as string[]} currentStep={deal.currentStep | 0} finishedSteps={deal.finishedSteps}></Steps>
            <br></br>
           {deal.currentStep >= 7 
            && deal.dealStatus == "COMPLETED" 
            && userContext?.user.user.role == "BUYER"
            &&
            <Popup content='Move the file to the refinance tab' 
              trigger={ 
                <Button 
                  onClick={()=>{
                    updateDeal({dealStatus:"REFINANCING"})
                  }}
                icon="plus" floated='right' color="green">Will Refinance</Button> } /> 
                }
            {deal.currentStep >= 7 
            && deal.dealStatus != "COMPLETED" 
            && deal.dealStatus != "REFINANCING" 
            && deal.dealStatus != "REFINANCED" &&
            <Popup content='Move the file to completed tab' 
            trigger={ 
              <Button 
                onClick={()=>{
                  updateDeal({dealStatus:"COMPLETED"})
                }}
              icon="plus" floated='right' color="green">Completed</Button> } />
                }
            {deal.dealStatus == "REFINANCING" 
            && userContext?.user.user.role == "TITLE"
            &&
            <Popup content='Move file to REFINANCE COMPLETE' 
            trigger={ 
              <Button 
                onClick={()=>{
                  updateDeal({dealStatus:"REFINANCED", refinancedDate:new Date()})
                }}
              icon="plus" floated='right' color="green">Refinance Closed</Button> } />
            }
                
            { loanView && <LoanTermModal deal={deal} dealId={dealId} approveLoan={approveLoan}/>}
            <Tab activeIndex={activePane} onTabChange={(e, { activeIndex }) => setActivePane(activeIndex as number) } panes={deal.loanTerms ? panes.concat([loanTab]) : panes} />
            <br></br>
            {!loadingMap && <StyledMapWithAnInfoBox center={center}/>}
            </Segment>
        );
    }
}

function OuterDropzone({dealId, updateCb}:{dealId:string|undefined, updateCb:(newFile:FileDocument)=>void}) {
    const {getRootProps} = useDropzone({
      // Note how this callback is never invoked if drop occurs on the inner dropzone
      onDrop: files => {
        const formData = new FormData()
        formData.append('file', files[0])
        axios.post(`/api/deals/${dealId}/files`, formData, {
        }).then(({data}) => {
            console.log(data.data)
            updateCb(data.data)
        })
      }
    });
  
    return (
      <div className="container">
        <div {...getRootProps({className: 'dropzone'})}>
            
          <Header icon textAlign='center'>
            <Icon name='upload' />
                    <Header.Content>Drag and Drop files here or click to browse</Header.Content>
            </Header> 
        </div>
      </div>
    );
}


// TODO: add in delete progress
function ProgressExampleAutoSuccess() {
    let [state, setState] = React.useState({ percent: 0 })
  
    function toggle(){}
      setState((prevState) => ({
        percent: prevState.percent === 0 ? 100 : 0,
      }))
  
      return (
        <div>
          <Progress percent={state.percent} autoSuccess />
          <Button onClick={toggle}>Toggle Complete</Button>
        </div>
      )
}

const Files = ({files, updateDeal}:{files:FileDocument[], updateDeal:(files:FileDocument[])=>void}) =>{
    function handleDownload (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, data:any, file:FileDocument) {
        fetch(`/api/files/${file.id}/downloadurl`)
            .then(res => res.json())
            .then(
                (result) => {
                    fetch(result.data)
                    .then((response) => response.blob())
                    .then((blob) => {
                      // Create blob link to download
                      const url = window.URL.createObjectURL(
                        new Blob([blob]),
                      );
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute(
                        'download',
                        file.name!,
                      );
                  
                      // Append to html link element page
                      document.body.appendChild(link);
                  
                      // Start download
                      link.click();
                  
                      // Clean up and remove the link
                      link.parentNode?.removeChild(link);
                    });

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("here", error)
                }
            )
    }
    function handleDelete (event:React.MouseEvent<HTMLButtonElement, MouseEvent>, data:any, file:FileDocument) {
        fetch(`/api/files/${file.id}`,{
            method: 'DELETE',
        })
            .then(
                (result) => {
                    if(result.ok){
                        updateDeal(files.filter((f)=>f.id != file.id))
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("here", error)
                }
            )
    }
    return (<>
    <List divided selected size="big" verticalAlign='middle'>
        {files.map((file, index)=>{
            return (<List.Item key={index}>
                <List.Content floated='right'>
                  <Button size='tiny' color="green" test="true" as="a" target="_blank" onClick={(event, data) => handleDownload(event, data, file)}><Icon name="download"/></Button>
                  <Button size='tiny' color="red" onClick={(event, data) => handleDelete(event, data, file)}><Icon name="delete"/></Button>
                </List.Content>
                <Icon name="file alternate outline" size="large" />
                <List.Content>{file.name}</List.Content>
              </List.Item>
        )
        })}
    </List>
    </>    
    )   
}