import React from "react";
import { Deal, Message as MessageModel } from "../../../Models";
import { ToastContext } from '../../../utils/ToastContext';
import { Button, Comment, Form, Header, Icon, Input, Label, Popup, Rail, Segment, TextArea } from "semantic-ui-react";
import { formatDate } from "./Utils";
import { info } from "console";

let saveTimer: string | number | NodeJS.Timeout | undefined;
export const InfoPane = ({deal, updateDeal}:{deal:Deal, updateDeal:(deal:Deal)=>void}) =>{
    let {setToast} = React.useContext(ToastContext)

    const newInfoState = {
        seller:{name:"", email:"", number:"", notes:""},
        buyer:{name:"", email:"", number:"", notes:""},
        loan:{arv:"", rehab:"", purchasePrice:"", downpayment:"", notes:"", linkToPicture:""}
    }
    const [info, setInfoState] = React.useState(deal.info)
    function handleSubmit(info: any){
        console.log(info)
        console.log("propped")
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({info})
        };
        fetch(`/api/deals/${deal.id}`,requestOptions)
         .then(res => res.json())
         .then(res => {
            console.log(res)
            if(res.error){
                setToast({message:"Info failed to update", color:"red", open:true})
            }else {
                setToast({message:"Information updated", color:"green", open:true})
                updateDeal({...deal, info:res.data.deal.info})
            }
                   
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("here", error)
                }
            ).catch(err=>console.log(err))
    }
    function handleChange(this: any, formPart: any, event:any, data: any){
        clearTimeout(saveTimer)
        console.log(info)
        console.log(formPart, event, data)
        if (data?.name){
            let oldInfo = info[formPart]
            let newInfo = {...info, [formPart]:{...oldInfo, [data.name]:data.value}}
            setInfoState(newInfo)
            saveTimer = setTimeout(handleSubmit.bind(this, newInfo), 1500)
        } else {
            setInfoState({...deal.info, street1:event.target.value})
        }
    }
    return (<>
        <Segment.Group horizontal>
      <Segment>
        <Header>Seller info:</Header>
        <Form>
            <Form.Field>
            <label>Name</label>
            <Input placeholder='Name' name='name' value={info.seller?.name} onChange={handleChange.bind(this, "seller")}/>
            </Form.Field>
            <Form.Field>
            <label>Number</label>
            <Input placeholder='Number' name='number' value={info.seller?.number} onChange={handleChange.bind(this, "seller")}/>
            </Form.Field>
            <Form.Field>
            <label>Email</label>
            <Input placeholder='Email' name='email' value={info.seller?.email} onChange={handleChange.bind(this, "seller")}/>
            </Form.Field>
            <Form.Field>
            <label>Notes</label>
            <TextArea placeholder='Notes...' name='notes' value={info.seller?.notes} onChange={handleChange.bind(this, "seller")}/>
            </Form.Field>
        </Form> 
        </Segment>
      <Segment>
      <Header>Buyer Info:</Header>
      <Form>
      <Form.Field>
            <label>Name</label>
            <Input placeholder='Name' name='name' value={info.buyer?.name} onChange={handleChange.bind(this, "buyer")}/>
            </Form.Field>
            <Form.Field>
            <label>Number</label>
            <Input placeholder='Number' name='number' value={info.buyer?.number} onChange={handleChange.bind(this, "buyer")}/>
            </Form.Field>
            <Form.Field>
            <label>Email</label>
            <Input placeholder='Email' name='email' value={info.buyer?.email} onChange={handleChange.bind(this, "buyer")}/>
            </Form.Field>
            <Form.Field>
            <label>Notes</label>
            <TextArea placeholder='Notes...' name='notes' value={info.buyer?.notes} onChange={handleChange.bind(this, "buyer")}/>
            </Form.Field>
        </Form> 
      </Segment>
      <Segment>
      <Header>
        <Popup content='Add a lender to enable loan information' 
            trigger={<Icon name="info circle" size="small" style={
                {"font-size": ".75em", "min-height": "auto"}
            }/>
            } />Loan info</Header>
      {deal.needLender && <Form>
            <Form.Field>
            <label>ARV</label>
            <Input placeholder='ARV' name='arv' value={info.loan?.arv} onChange={handleChange.bind(this, "loan")}/>
            </Form.Field>
            <Form.Field>
            <label>Rehab</label>
            <Input placeholder='Rehab' name='rehab' value={info.loan?.rehab} onChange={handleChange.bind(this, "loan")}/>
            </Form.Field>
            <Form.Field>
            <label>Purchase Price</label>
            <Input placeholder='Purchase Price'  name='purchasePrice'  value={info.loan?.purchasePrice} onChange={handleChange.bind(this, "loan")}/>
            </Form.Field>
            <Form.Field>
            <label>NVE Downpayment</label>
            <Input placeholder='Downpayment' name='downpayment'  value={info.loan?.downpayment} onChange={handleChange.bind(this, "loan")}/>
            </Form.Field>
            <Form.Field>
            <label>Pictures Link</label>
            <Input placeholder='Link to Pictures...' name='pictureLink' value={info.loan?.pictureLink} onChange={handleChange.bind(this, "loan")}/>
            </Form.Field>
            <Form.Field>
            <label>Notes</label>
            <TextArea placeholder='Notes...' name='notes' value={info.loan?.notes} onChange={handleChange.bind(this, "loan")}/>
            </Form.Field>
        </Form> }
      </Segment>
    </Segment.Group>
    </>     
    )
}