import FileDocument from './FileDocument'
import Message from './Message';
import Task from './Task'
import { User } from './User';
var future = new Date();
future.setDate(future.getDate() + 30);
export default class Deal {
    id?:number;
    user:User = {firstName:"", lastName:"",};
    buyerId?:number; 
    assignorId?: number;
    tasks: Task[] = [];
    files: FileDocument[] = [];
    messages: Message[] = [];
    street1: string = "";
    street2: string = "";
    city: string = "";
    state : string = "";
    zip : string  = "";
    closingDate?: Date;
    hasUsers: User[] = [];
    currentStep: number = 0;
    finishedSteps: number[] = [];
    info:any = {};
    needLender:boolean = false;
    loanTerms:string = "";
    dealStatus:string = "INPROGRESS";
    title?: string ;
    refinancedDate?: Date;

}