import React from "react";
import { Deal, Message as MessageModel } from "../../../Models";
import { ToastContext } from '../../../utils/ToastContext';
import { Button, Comment, Dropdown, Form, Header, Icon, Input, Label, Popup, Rail, Segment, TextArea } from "semantic-ui-react";
import { formatDate } from "./Utils";
import { info } from "console";
import Autocomplete from "react-google-autocomplete";
import { STATES } from "../../../utils/states";
import moment from "moment";
import addresser from 'addresser';
import { getCorrectDate } from "../../../utils/helper";



let saveTimer: string | number | NodeJS.Timeout | undefined;
export const GeneralPane = ({deal, updateDeal}:{deal:Deal, updateDeal:(deal:Deal)=>void}) =>{
    let {setToast} = React.useContext(ToastContext)

    const [newDealState, setNewDealState] = React.useState({...deal})
    function handleSubmit(newDealState: any){
        console.log(newDealState)
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newDealState)
        };
        fetch(`/api/deals/${newDealState.id}`,requestOptions)
         .then(res => res.json())
         .then(res => {
            console.log(res)
            if(res.error){
                setToast({message:res.message, color:"red", open:true})
            }else {
                setToast({message:"Information updated", color:"green", open:true})
                updateDeal({...deal, ...newDealState})
            }
                   
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("here", error)
                }
            ).catch(err=>console.log(err))
    }
    function handleChange(event:any, data: any){
        console.log(event, data)
        if (data?.name){
          if (data.name == "closingDate"){
            setNewDealState({...newDealState, [data.name]:getCorrectDate(data.value)})
          } else {
            setNewDealState({...newDealState, [data.name]:data.value})
          }
        } else {
            setNewDealState({...newDealState, street1:event.target.value})
        }    }
    return (
            <Form size='large' id='updateDealForm' className="clearing">
                <div className="field">
                <div className="ui fluid left icon input">
                    {/* <input placeholder="Street 1" name="street1" type="text" value="42nd"> */}
                    <Autocomplete options={{types:[]}}
                    apiKey={'AIzaSyDPblG_QTjIbZtIUsq0En4bicR2TyMK8BU'}
                    onPlaceSelected={(place, input) =>{
                        let address = place.formatted_address!;
                        let parsedAddress = addresser.parseAddress(address)
                        console.log(parsedAddress)
                        console.log(input)
                        updateDeal({...deal,
                            zip:parsedAddress.zipCode,
                            street1:parsedAddress.addressLine1,
                            city:parsedAddress.placeName,
                            street2:"",
                            state:parsedAddress.stateAbbreviation,
                            closingDate:newDealState.closingDate
                        })
                        // @ts-ignore
                        input.value = parsedAddress.addressLine1
                    } }

                    inputAutocompleteValue={newDealState.street1}
                    defaultValue={deal.street1}
                    // @ts-ignore
                    onChange={handleChange}
                    placeholder='Street1'
                />
                <i aria-hidden="true" className="home icon"></i></div></div>
                
                <Form.Input fluid icon='home' iconPosition='left' placeholder='Street 2' name="street2"  value={newDealState.street2} onChange={handleChange}/>
                <Form.Group widths='equal'>
                <Form.Input fluid icon='home' iconPosition='left' placeholder='city' name="city" value={newDealState.city} onChange={handleChange}/>
                <Dropdown placeholder='State' options={STATES} selection name="state" onChange={handleChange}  value={newDealState.state}/>
                <Form.Input fluid icon='home' iconPosition='left' placeholder='zip' name="zip" value={newDealState.zip} onChange={handleChange}/>
                </Form.Group>
                <label>Closing Date</label>
                <Form.Input
                    default={moment(newDealState.closingDate).format('YYYY-MM-DD')}
                    icon='calendar alternate outline'
                    iconPosition='left'
                    placeholder='Closing Date'
                    type='date'
                    name="closingDate"
                    value={moment(newDealState.closingDate).format('YYYY-MM-DD')} onChange={handleChange}
                    
                />
                <div>
                <Button floated="right"
                    onClick={() => {
                        handleSubmit(newDealState)
                    }}
                    positive
                    >Update Deal</Button>
                    </div>
            </Form> 
    )
}