import React from "react";
import { Accordion, Button, Header, Icon, Segment } from "semantic-ui-react";
import { Deal, Task } from "../../../Models";
import { ToastContext } from "../../../utils/ToastContext";
import { UserContext } from "../../../utils/UserContext";

export const Tasks = ({tasks, updateDeal, deal}:{tasks:Task[],deal:Deal, updateDeal:Function}) => {
    //@ts-ignore
  const {user, updateUser} = React.useContext(UserContext)
  console.log(user)
    let {setToast} = React.useContext(ToastContext)
      let [state, setState] = React.useState({ activeIndex: -2, transitioning:false })
  
      function handleClick(e: any, titleProps: { index?: any; }) {
        console.log(e)
        if (e.target.localName == "button" || e.target.localName == "i"){
          return false
        }
        const { index } = titleProps
        const { activeIndex } = state
        const newIndex = activeIndex === index ? -1 : index
    
        setState({ activeIndex: newIndex, transitioning:false })
      }
      
      function completeTask(task: Task) {
          const requestOptions = {
              method: 'PUT',
          };
          fetch(`/api/tasks/${task.id}/complete`,requestOptions)
           .then(res => res.json())
           .then(res => {
              console.log(res)
              if(res.error){
                  setToast({message:"Something went wrong", color:"red", open:true})
              }else {
                let {message} = res
                task.completed = true;
                updateDeal({...deal})
                setToast({message, color:"green", open:true})
              }
                     
                  },
                  // Note: it's important to handle errors here
                  // instead of a catch() block so that we don't swallow
                  // exceptions from actual bugs in components.
                  (error) => {
                      console.log("here", error)
                  }
              ).catch(err=>console.log(err))
  
      }
  
      return (<>
          <Header dividing>To do</Header>
          
          <Accordion fluid>
  
          {tasks.filter(t => t.defaultTask.owner == user.user.role).filter(t=>t.defaultTask.step == deal.currentStep).map((task, index)=>{
  
              return (<Segment key={index}>
              {/* <Button disabled={task.completed} color={task.completed ? "grey":"green"} floated='right' size='tiny' icon="check" onClick={()=>{completeTask(task)}}></Button> */}
              <Accordion.Title key={index}
              active={state.activeIndex === index}
              index={index}
              onClick={handleClick}
            >
              <Icon name='dropdown' />
              {task.completed && <s>{task.defaultTask.title}</s>}
              {!task.completed && task.defaultTask.title}
            </Accordion.Title>
            <Accordion.Content active={state.activeIndex === index}>
              <p>
               {task.defaultTask.info}
              </p>
              
            </Accordion.Content>
            </Segment>
          )
          })}
      </Accordion>
      </>    
      )
  
  }