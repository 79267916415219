import * as React from 'react';
import { Icon, Label, Menu, Table, Tab, Button, Modal, Header, Form, Segment, Select, Dropdown} from 'semantic-ui-react'
import {  Link } from "react-router-dom";
import DealRow from './DealRow';
import Deal from '../../Models/Deal'
import { STATES } from '../../utils/states'
import e from 'express';
import { ToastContext } from '../../utils/ToastContext';
import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from "react-google-autocomplete";
import addresser from 'addresser';
import moment from 'moment';
import { formatDate } from './DealDetails/Utils';
import { getCorrectDate } from '../../utils/helper';




export function AddModal({handleSubmit, newDealState, handleChange, setNewDealState}:
    {handleSubmit:any, newDealState:Deal, handleChange:any, setNewDealState:any}) {
    const [open, setOpen] = React.useState(false)
    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        trigger={<Button style={{"marginBottom":"10px"}} floated="right" color="green">Add</Button>}
      >
        <Modal.Header>New Deal</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form size='large' id='newDealForm'>
                <Segment>
                <div className="field">
                <div className="ui fluid left icon input">
                    {/* <input placeholder="Street 1" name="street1" type="text" value="42nd"> */}
                    <Autocomplete options={{types:[]}}
                    apiKey={'AIzaSyDPblG_QTjIbZtIUsq0En4bicR2TyMK8BU'}
                    onPlaceSelected={(place, input) =>{
                        let address = place.formatted_address!;
                        let parsedAddress = addresser.parseAddress(address)
                        console.log(parsedAddress)
                        console.log(input)
                        setNewDealState({
                            zip:parsedAddress.zipCode,
                            street1:parsedAddress.addressLine1,
                            city:parsedAddress.placeName,
                            street2:"",
                            state:parsedAddress.stateAbbreviation,
                            closingDate:newDealState.closingDate
                        })
                        // @ts-ignore
                        input.value = parsedAddress.addressLine1
                    } }

                    inputAutocompleteValue={newDealState.street1}
                    onChange={handleChange}
                    placeholder='Street1'
                />
                <i aria-hidden="true" className="home icon"></i></div></div>
                
                <Form.Input fluid icon='home' iconPosition='left' placeholder='Street 2' name="street2"  value={newDealState.street2} onChange={handleChange}/>
                <Form.Group widths='equal'>
                <Form.Input fluid icon='home' iconPosition='left' placeholder='city' name="city" value={newDealState.city} onChange={handleChange}/>
                <Dropdown placeholder='State' options={STATES} selection name="state" onChange={handleChange}  value={newDealState.state}/>
                <Form.Input fluid icon='home' iconPosition='left' placeholder='zip' name="zip" value={newDealState.zip} onChange={handleChange}/>
                </Form.Group>
                <label>Closing Date(default to 1 month from today)</label>
                <Form.Input
                    default={moment(newDealState.closingDate).format('YYYY-MM-DD')}
                    icon='calendar alternate outline'
                    iconPosition='left'
                    placeholder='Closing Date'
                    type='date'
                    name="closingDate"
                    value={moment(newDealState.closingDate).format('YYYY-MM-DD')} onChange={handleChange}
                    
                />
                </Segment>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={() =>setOpen(false)}>
            Cancel
          </Button>
          <Button
            content="Add"
            labelPosition='right'
            icon='checkmark'
            onClick={async () => {
                if (await handleSubmit()){
                  setOpen(false)
                }
            }}
            positive
          />
        </Modal.Actions>
      </Modal>
    )
  }
export default function DealTable(
    {deals, setDeals, addButtonVisible, refinanced}:{deals:Deal[], setDeals:any,addButtonVisible:boolean, refinanced:boolean}
){

    let oneMonthNearestFriday = new Date()
    oneMonthNearestFriday.setDate(oneMonthNearestFriday.getDate() + 30)
    oneMonthNearestFriday.setDate(oneMonthNearestFriday.getDate() + (12 - oneMonthNearestFriday.getDay()) % 7);
    let {setToast, setToastError} = React.useContext(ToastContext)
    React.useEffect(()=>{

    }, [deals])

    const [newDealState, setNewDealState] = React.useState<Deal>({...new Deal(), closingDate:oneMonthNearestFriday})
    const [location, setLocation] = React.useState({lat:"", lng:""})
   
    function handleSubmit(){
        console.log(newDealState)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify(newDealState)
        };
        console.log(requestOptions)
        let p = new Promise((res, rej)=>{
          fetch("/api/deals", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result)
              let message = result.message
              if (result.error){
                setToastError(message)
                return res(false)
              } else {
                setToast({message, color:"green", open:true})
                setNewDealState(new Deal())
                res(true)
              }
                setDeals(deals.concat([result.deal]))
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              console.log("deal create error")
             setToastError(error)
             console.log(error)
            }
          )
        })
       return p
    }
    function handleChange(event: { target: { name: any; value: any; }; }, data: { name: any; value: any; }){
        console.log(event, data)
        if (data?.name){
          if (data.name == "closingDate"){
            setNewDealState({...newDealState, [data.name]:getCorrectDate(data.value)})
          } else {
            setNewDealState({...newDealState, [data.name]:data.value})
          }
        } else {
            setNewDealState({...newDealState, street1:event.target.value})
        }
        // Object.defineProperty(event.target, "defaultValue", {
        //     configurable: true,
        //     get() { return "" },
        //     set() {},
        //   });
    }
    return (<>
        { addButtonVisible && 
        <AddModal handleSubmit={handleSubmit}
                newDealState={newDealState} 
                handleChange={handleChange}
                setNewDealState={setNewDealState}/>}
        <br></br>
        <Table selectable>
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>{refinanced ? "Refinanced Date" : "Closing Date"}</Table.HeaderCell>
                {/* <Table.HeaderCell>Outstanding Tasks</Table.HeaderCell> */}
                <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
            </Table.Header>

            <Table.Body>
            {deals.map(deal => (
                <DealRow key={deal.id} deal={deal}/>
            ))}
            </Table.Body>

            <Table.Footer>
            <Table.Row>
            </Table.Row>
            </Table.Footer>
        </Table>
        </>
    );
}
