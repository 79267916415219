import './App.css';
import Dashboard from './components/Dashboard';
import 'semantic-ui-css/semantic.min.css'
import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';

import { Container, Icon, Message, Segment, TransitionablePortal } from 'semantic-ui-react'
import { createBrowserRouter, Link, RouterProvider,useNavigate } from 'react-router-dom';
import { UserContext } from './utils/UserContext';
import { useContext, useEffect, useState, Dispatch } from 'react';
import DealDetail from './components/Deals/DealDetail';
import MenuNavigation from './components/MenuNavigation';
import { ToastContext, ToastInfoInterface } from './utils/ToastContext';
import ForgotPassword from './components/ForgotPasswordForm';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginForm/>,
  },
  {
    path: "/register",
    element: <Container>
      <MenuNavigation></MenuNavigation>
      <RegisterForm/>
      </Container>
  },
  {
    path: "/dashboard",
    element: <Container>
      <MenuNavigation></MenuNavigation>
      <Dashboard />
    </Container>
  },
  {
    path: "/deals/:dealId",
    element: <Container>
      <MenuNavigation></MenuNavigation>
        <DealDetail/>
      </Container>,
  },
  {
    path: "/deals/:dealId/:action",
    element: <Container>
      <MenuNavigation></MenuNavigation>
        <DealDetail/>
      </Container>,
  },
  {
    path: "/forgotpassword",
    element: <Container>
      <MenuNavigation></MenuNavigation>
        <ForgotPassword/>
      </Container>,
  },
]);

function App() {
  const [user, setUser] = useState({loggedIn:false, user:{}});
  const [toast, setToastState] = useState<ToastInfoInterface>({open:false, color:"green", message:""});
  function setErrorToastState(err: { toString: any; }){
    setToastState({message:err.toString(), color:"red", open:true})
  }
  const [cState, setcState] = useState({isLoaded:false, error:false})
  useEffect(()=>{
    fetch("/api/checkstate")
    .then(res => res.json())
    .then(
      (result) => {
        console.log("checkState", result)
        if (result.user){
          setcState({...cState, isLoaded:true})
          if (!user.loggedIn){
            setUser({loggedIn:true, user:result.user})
          }
        } else {
          setcState({...cState, isLoaded:true})
          if (user.loggedIn){
            setUser({loggedIn:false, user:{}})
          }
        }
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
      console.log("login error")
      console.log(error)
      setcState({...cState, error:true})
      }
    ).catch((err)=>{
      console.log(err)
    })
  },[])
  const { error, isLoaded } = cState;
  let child;
  if (error) {
      child = <div>Loading error, please refresh</div>;
  } else if (!isLoaded) {
    child =  <div>Loading...</div>;
  } else {
    child =  <RouterProvider router={router} />
  }
  return (

    <Container>
      <UserContext.Provider value={{user, updateUser:setUser}}>
      <ToastContext.Provider value={{info:toast, setToast:setToastState, setToastError:setErrorToastState}}>

        {child}
        <TransitionablePortal
            open={toast.open}
            onClose={()=>setToastState({...toast, open:false})}
            onOpen={()=>{
              setTimeout(function(){
                setToastState({...toast, open:false})
              },5000)}
            }
            transition={{ animation:"fly down", duration:500 }}
          >
            <Message id="toastMessages" compact color={toast.color}
              style={{
                "floated": "left",
                "position": 'fixed',
                "bottom": '0%',
                "zIndex": 1000,
                "font-size":"1.25em",
                "borderTop": "5px solid #21ba45!important",
            }}>{toast.message} &nbsp;
                <Icon name='delete' onClick={()=>setToastState({...toast, open:false})}/>
          </Message>
          </TransitionablePortal>
          </ToastContext.Provider>
      </UserContext.Provider>
      
    </Container>
  );
}

export default App;
