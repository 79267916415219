// @ts-nocheck
import React, { useEffect } from 'react'
import { Button, Form, Grid, Header, Image, Message, Segment } from 'semantic-ui-react'
import {  Link, useNavigate } from "react-router-dom";
import { UserContext } from '../utils/UserContext';
import { ToastContext } from '../utils/ToastContext';
const MessageExampleNegative = (message) => (
  <Message negative>
    <Message.Header>Failed Login</Message.Header>
    <p>{message}</p>
  </Message>
)


export default function LoginForm(){
  const navigate = useNavigate()
  const userContext = React.useContext(UserContext)
  const {setToast, setToastError} = React.useContext(ToastContext)

  const [loginState, setLoginState] = React.useState({
      password:"", 
      email:"",
      error:false,
      errorMessage:""
  });
  useEffect(()=>{
    console.log("login", userContext)
    if (userContext?.user.loggedIn){
      navigate('/dashboard')
    }
  },[userContext?.user])
 
    function handleChange(event) {
        setLoginState({...loginState, [event.target.name]: event.target.value})
      }
    
    function handleSubmit(event) {
        event.preventDefault();
        // NOTE: uses passport default of username/password 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=utf-8' },
            body: JSON.stringify({username:loginState.email, password:loginState.password})
        };
        console.log(requestOptions)
        fetch("/api/login", requestOptions)
          .then(res => res.json())
          .then(
            (result) => {
              console.log("login result", result)
              if (result.error){
                setLoginState({...loginState, error:true, errorMessage:result.message})
              } else {
                userContext.updateUser({loggedIn:true, user:result})
                setLoginState({...loginState, error:false, errorMessage:""})
                navigate('/dashboard')
              }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
             console.log("login error")
             console.log(error)
             setToastError(error)
            }
          )
        console.log(event)
      }
      // TODO: CONFIRM PASSWORD
        return <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>

            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' color='yellow' textAlign='center'>
                    <Image src='/nveiconwhite.png' /> Log-in to your account
                </Header>
                { loginState.error && MessageExampleNegative(loginState.errorMessage)}
                <Form size='large' onSubmit={handleSubmit}>
                    <Segment stacked>
                    <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail address' name="email" value={loginState.email} onChange={handleChange}/>
                    <Form.Input
                        fluid
                        icon='lock'
                        iconPosition='left'
                        placeholder='Password'
                        type='password'
                        name="password"
                        value={loginState.password} 
                        onChange={handleChange}
                    />
                    <Button color='yellow' fluid size='large'>
                        Login
                    </Button>
                    <br></br>
                    <p>
                      Forgot password? &nbsp;  <Link to='/forgotpassword'> Reset</Link>
                    </p>
                    </Segment>
                </Form>
                <Message>
                   New to us? &nbsp;  <Link to='/register'> Sign Up</Link>
                </Message>
            </Grid.Column>
        </Grid>
    }
