import * as React from 'react';
import { Icon, Label, Menu, Table, Tab, Button } from 'semantic-ui-react'
import Moment from 'moment';
import {  useNavigate } from "react-router-dom";
import { DealSteps } from './DealDetails/Utils';
import { STEP_DISPLAY } from '../../utils/constants';
import { Deal } from '../../Models';

export function displayHouseAddress(deal:Deal){
    return (<>
        <p>{`${deal.street1} ${deal.street2}`}</p>
        { deal.street2 && <p>{deal.street2}</p> }
        <p>{`${deal.city}, ${deal.state}, ${deal.zip}`}</p>
        </>
    )
}
function displayStep(currentStep:number){
    return STEP_DISPLAY[(currentStep)]
}
export default function DealRow({deal}:{deal:Deal}) {
    const navigate = useNavigate()

        return (         
            <Table.Row className="clickable" onClick={()=>navigate(`/deals/${deal.id}`)}>
                <Table.Cell>{displayHouseAddress(deal)}</Table.Cell>
                <Table.Cell>{displayStep(deal.currentStep)}</Table.Cell>
                <Table.Cell>{deal.dealStatus == "REFINANCED" ? Moment(deal.refinancedDate).format('D MMMM YYYY') : Moment(deal.closingDate).format('D MMMM YYYY')}</Table.Cell>
                {/* <Table.Cell>{deal.tasks?.length | 0}</Table.Cell> */}
                <Table.Cell>
                    <Button floated="right" onClick={()=>navigate(`/deals/${deal.id}`)}>View</Button>
                    <Button floated="right" onClick={(e)=>{navigate(`/deals/${deal.id}/update`); e.stopPropagation();}}>Update</Button></Table.Cell>
            </Table.Row>
        );
    }