import { Button, Form, Icon, Label, Modal, Step, TextArea } from 'semantic-ui-react'

export enum DealSteps {
  "File intake",
  "Title search starting",
  "Title cleared",
  "Loan approved",
  "Funded",
  "All parties closed",
  "Deal recorded",
}

export const Steps = ({steps, currentStep, updateStep, finishedSteps}:{steps:string[], currentStep:number, updateStep:(index:number)=>void, finishedSteps:number[]}) => {
    return (<div>
        <Step.Group size="tiny" fluid>
        {steps.map((step, index)=>{
            return (
            <Step key={index} link={finishedSteps.indexOf(index) != -1} onClick={(e)=>{ e.preventDefault(); updateStep(index)}}>
                <Step.Content>
                <Icon color={finishedSteps.indexOf(index) != -1 ? 'green': 'black'} name={finishedSteps.indexOf(index) != -1 ? 'check circle outline': 'circle outline' } />
                <Step.Description>{step}</Step.Description>
                </Step.Content>
            </Step>
            )
        })}
    </Step.Group>
    </div>
  )
}

export function formatDate(newDate:Date) {
    const months = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December',
    }
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const d = newDate
    const year = d.getFullYear()
    const date = d.getDate()
    const monthIndex = d.getMonth()
    // @ts-ignore
    const monthName = months[d.getMonth()]
    const dayName = days[d.getDay()] // Thu
    const hour = d.getHours()
    const minutes = d.getMinutes()
    const formatted = `${dayName}, ${date} ${monthName} ${year}: ${hour}:${minutes}`
    return formatted.toString()
  }